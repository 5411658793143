import React from 'react';
import styled from 'styled-components';
import {Menu, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {BookOpen} from '@styled-icons/boxicons-regular/BookOpen';
import {Plus} from '@styled-icons/boxicons-regular/Plus';
import {Minus} from '@styled-icons/boxicons-regular/Minus';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import SearchCollection from '../../Components/SearchCollection';
import Morale from '../../Components/Morale';
import Teams from '../../Components/Teams';

const TITLE = '關於我們';
const ROUTES = [
  {
    key: '/about?group=introduction',
    label: '公會簡介',
    children: [
      {
        key: '/about?group=introduction&category=history',
        label: '軌跡-歷史沿革',
        children: [],
      },
      {
        key: '/about?group=introduction&category=team',
        label: '團隊-組織架構',
        children: [],
      },
      {
        key: '/about?group=introduction&category=thinking',
        label: '思維-使命理念',
        children: [],
      },
      {
        key: '/about?group=introduction&category=opposition',
        label: '在野-改革參與',
        children: [],
      },
      {
        key: '/about?group=introduction&category=overview',
        label: '視野-國際交流',
        children: [],
      },
      {
        key: '/about?group=introduction&category=personnel',
        label: '公會歷屆監理事',
        children: [],
      },
      {
        key: '/about?group=introduction&category=contact',
        label: '聯絡方式',
        children: [],
      },
    ],
  },
  {
    key: '/about?group=regulation',
    label: '公會規章',
    children: [
      {
        key: '/about?group=regulation&category=association',
        label: '會務相關法規',
        children: [
          {key: '', label: '台北律師公會章程'},
          {
            key: '',
            label: '台北律師公會受懲戒處分會員自費接受額外律師倫理規範研習辦法',
          },
          {
            key: '',
            label: '台北律師公會訊息刊登服務管理辦法',
          },
          {key: '', label: '台北律師公會有效委託書計算辦法'},
          {key: '', label: '台北律師公會會員權益申訴處理辦法'},
          {
            key: '',
            label: '台北律師公會會員入會須知',
          },
          {
            key: '',
            label: '台北律師公會入退會、跨區執業暨繳費細則',
          },
          {
            key: '',
            label: '台北律師公會會員福利事項表',
          },
          {key: '', label: '台北律師公會通訊投票選舉辦法'},
          {key: '', label: '台北律師公會委員會設置辦法'},
          {key: '', label: '台北律師公會委員會活動舉辦辦法'},
          {
            key: '',
            label: '台北律師公會常年會費暨跨區執業費用開徵及催收作業準則',
          },
          {
            key: '',
            label: '台北律師公會理監事選舉選務工作辦法',
          },
          {
            key: '',
            label: '台北律師公會準會員證書屆期處理程序',
          },
          {
            key: '',
            label: '台北律師公會會員福利金支用辦法',
          },
          {
            key: '',
            label: '台北律師公會跨區執業申請須知',
          },
          {
            key: '',
            label: '性騷擾防治措施及申訴處理辦法',
          },
        ],
      },
      {
        key: '/about?group=regulation&category=business',
        label: '業務相關法規',
        children: [
          {key: '', label: '台北律師公會會館募款辦法'},
          {key: '', label: '台北律師公會會館營運辦法'},
          {key: '', label: '台北律師公會會員在職進修辦法'},
          {key: '', label: '台北律師公會會員在職進修課程時數採計標準'},
          {key: '', label: '台北律師公會會員在職進修課程網路學習辦法'},
          {key: '', label: '台北律師公會網路學習課程使用規則'},
          {
            key: '',
            label: '台北律師公會辦理「上市(櫃)公司董事、監察人進修課程」辦法',
          },
          {
            key: '',
            label: '台北律師公會推薦會員前往國外法學院攻讀學位審查辦法',
          },
          {
            key: '',
            label:
              '台北律師公會受理請求臺灣高等法院檢察署審查刑事有罪確定案件處理作業準則',
          },
          {
            key: '',
            label: '台北律師公會新進會員使用律師會談室試行辦法',
          },
          {
            key: '',
            label: '台北律師公會會館托育室使用須知',
          },
          {
            key: '',
            label: '台北律師公會會館會議室外借須知暨收費標準',
          },
          {
            key: '',
            label: '台北律師公會平民法律扶助實施要點',
          },
          {
            key: '',
            label: '台北律師公會優秀公益律師表揚辦法',
          },
        ],
      },
      {
        key: '/about?group=regulation&category=practicing',
        label: '律師執業相關法規',
        children: [
          {
            key: '',
            label: '律師法',
            query: {
              labels: '關於我們-公會規章-律師執業相關法規',
            },
          },
          {key: '', label: '律師倫理規範'},
          {key: '', label: '律師推展業務規範'},
          {key: '', label: '台北律師公會律師見證規則'},
          {
            key: '',
            label: '台北律師公會會員相互間暨與委任當事人間爭議調處辦法',
          },
          {key: '', label: '律師執業相關函釋'},
          {key: '', label: '台北律師公會倫理風紀委員會組織規程'},
          {key: '', label: '台北律師公會會員違反倫理風紀案件處理程序'},
          {
            key: '',
            label:
              '台北律師公會倫理風紀案件調查詢問會錄音及錄製之資料保管注意要點',
          },
        ],
      },
      {
        key: '/about?group=regulation&category=morale',
        label: '律師倫理案例選輯',
        children: [],
      },
    ],
  },
  {
    key: '/about?group=environment',
    label: '會館巡禮',
    children: [
      {
        key: '/about?group=environment&category=pictures',
        label: '會館寫真',
        children: [],
      },
      {
        key: '/about?group=environment&category=operator',
        label: '台北律師公會會館營運辦法',
        children: [],
      },
      {
        key: '/about?group=environment&category=charge',
        label: '台北律師公會會館會議室外借須知暨收費標準',
        children: [],
      },
    ],
  },
  {
    key: '/about?group=library',
    label: '圖書資訊室',
    children: [
      {
        key: '/about?group=library&category=introduction',
        label: '館藏介紹',
        children: [],
      },
      {
        key: '/about?group=library&category=fax',
        label: '傳真檢索',
        children: [],
      },
      {
        key: '/about?group=library&category=search',
        label: '館藏檢索',
        children: [],
      },
      {
        key: '/about?group=library&category=university-serial',
        label: '大學期刊檢索',
        children: [],
      },
      {
        key: '/about?group=library&category=borrow',
        label: '各大學圖書館借閱方式',
        children: [],
      },
    ],
  },
  {
    key: '/about?group=member',
    label: '台北律師公會會員動態',
    children: [],
  },
];

function About(props) {
  const params = qs.parse(props.location.search);
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const [record, setRecord] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [expands, setExpands] = React.useState(null);

  const handleCustomComponent = (search) => {
    if (search.indexOf('?group=library&category=search') > -1) {
      return [
        true,
        <SearchCollection
          collection="Library_Info"
          columns={[
            {title: '書名', dataIndex: 'name', key: 'title', width: '20%'},
            {title: '作者', dataIndex: 'author', key: 'author', width: '15%'},
            {
              title: '出版者',
              dataIndex: 'publisher',
              key: 'publisher',
              width: '15%',
            },
            {
              title: '櫃號',
              dataIndex: 'cabinet_number',
              key: 'cabinet_number',
              width: '10%',
            },
            {title: '卷', dataIndex: 'reel', key: 'reel', width: '2%'},
            {title: '期', dataIndex: 'period', key: 'period', width: '2%'},
            {title: 'ISBN', dataIndex: 'isbn', key: 'isdb', width: '2%'},
            {title: 'ISSN', dataIndex: 'issn', key: 'issn', width: '2%'},
            {title: 'ISRC', dataIndex: 'isrc', key: 'isrc', width: '2%'},
            {title: 'ISMN', dataIndex: 'ismn', key: 'ismn', width: '2%'},
            {title: 'GPN', dataIndex: 'gpn', key: 'gpn', width: '2%'},
          ]}
          query={(keyword) => ({
            $or: [
              {name: {$regex: keyword}},
              {author: {$regex: keyword}},
              {publisher: {$regex: keyword}},
              {isbn: {$regex: keyword}},
            ],
            is_open: '1',
          })}
        />,
      ];
    } else if (
      search.indexOf('?group=library&category=university-serial') > -1
    ) {
      return [
        true,
        <SearchCollection
          collection="Serial"
          columns={[
            {title: '書名', dataIndex: 'title', key: 'title', width: '20%'},
            {title: '期數', dataIndex: 'VOL_NO', key: 'VOL_NO', width: '10%'},
            {
              title: '目錄',
              dataIndex: 'catalog',
              key: 'catalog',
              render: (_, record) => {
                return (
                  <ol>
                    {record.catalog?.map((c) => (
                      <li>
                        <span style={{marginRight: 5}}>{c.WRITER} -</span>
                        <span style={{marginRight: 5}}>{c.TOPIC} - </span>
                        <span>第 {c.PAGE} 頁</span>
                      </li>
                    ))}
                  </ol>
                );
              },
            },
          ]}
          query={(keyword) => ({
            $or: [
              {title: {$regex: keyword}},
              {VOL_NO: {$regex: keyword}},
              {
                catalog: {
                  $elemMatch: {
                    TOPIC: {$regex: keyword},
                  },
                },
              },
              {
                catalog: {
                  $elemMatch: {
                    WRITER: {$regex: keyword},
                  },
                },
              },
            ],
          })}
        />,
      ];
    } else if (search.indexOf('?group=regulation&category=morale') > -1) {
      return [true, <Morale />];
    } else if (search.indexOf('?group=introduction&category=team') > -1) {
      return [true, <Teams />];
    }

    return [false, null];
  };

  const fetchData = React.useCallback(async () => {
    let route = getRouteInstance(params);
    let isCustomComponent = handleCustomComponent(props.location.search)[0];

    if (!route || isCustomComponent) {
      setRecord(null);
      setRecords(null);
      setExpands(null);

      return;
    }

    try {
      AppActions.setLoading(true);

      if (route.children.length === 0) {
        setRecords(null);
        setExpands(null);

        if (!record || record.title !== route.label) {
          setRecord(
            await JStorage.fetchOneDocument('Article_Default', {
              title: route.label,
            }),
          );
        }
      }

      if (route.children.length > 0) {
        setRecord(null);

        let recordsMatchRouteChildren = records?.every((r) => {
          let findIndex = route.children.findIndex((c) => c.label === r.title);
          return findIndex > -1;
        });

        if (!records || !recordsMatchRouteChildren) {
          const query = {};

          for (let i = 0; i < route.children.length; i++) {
            if (route.children[i].query) {
              const queryKeys = Object.keys(route.children[i].query);

              for (let k = 0; k < queryKeys.length; k++) {
                query[queryKeys[k]] = route.children[i].query[queryKeys[k]];
              }
            }
          }

          const results = await JStorage.fetchAllDocuments('Article_Default', {
            title: {$in: route.children.map((c) => c.label)},
            ...query,
          });

          const sortedResults = [];

          for (let i = 0; i < route.children.length; i++) {
            const index = results.findIndex(
              (r) => r.title === route.children[i].label,
            );
            if (index > -1) {
              sortedResults.push(results[index]);
            }
          }

          setRecords(sortedResults);
          setExpands(
            route.children.map((c) => ({label: c.label, expand: false})),
          );
        }
      }

      AppActions.setLoading(false);
    } catch (err) {
      console.warn(err);
      setRecord(null);
      setRecords(null);
      setExpands(null);
      AppActions.setLoading(false);
    }
  }, [params, records, record, props.location.search]);

  React.useEffect(() => {
    fetchData();
  }, [params, fetchData, record]);

  const getRouteInstance = ({group, category}) => {
    let element;

    if (group) {
      element = ROUTES.find((item) => item.key.indexOf(group) > -1);

      if (category) {
        element = element.children.find(
          (item) => item.key.indexOf(category) > -1,
        );
      }
    }

    return element;
  };

  return (
    <Wrapper mobile={mobile}>
      <div className="menu">
        <Menu mode="inline">
          <Menu.Item className="header">{TITLE}</Menu.Item>
          {ROUTES.map((item, index) => {
            if (item.children.length > 0) {
              return (
                <Menu.SubMenu
                  key={`root-${index}`}
                  className="route"
                  title={item.label}>
                  {item.children.map((subroute, index) => (
                    <Menu.Item
                      key={`subroute-${index}`}
                      onClick={() => AppActions.navigate(subroute.key)}>
                      {subroute.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={`root-${index}`}
                className="route"
                onClick={() => AppActions.navigate(item.key)}>
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <div className="gutter" />

      <div className="main">
        <h1 className="title">{getRouteInstance(params)?.label || '-- --'}</h1>

        {handleCustomComponent(props.location.search)[0] && (
          <div className="content">
            {handleCustomComponent(props.location.search)[1]}
          </div>
        )}

        {record && (
          <div className="content">
            <RichTextPreview key={record.id} content={record.content} />
          </div>
        )}
        {records && (
          <div className="content">
            {records.map((record, index) => {
              let findIndex = expands?.findIndex(
                (expand) => expand.label === record.title,
              );
              let isExpand = expands ? expands[findIndex]?.expand : false;

              return (
                <div className="expand-container" key={`expand-${index}`}>
                  <div
                    className="expand-title"
                    onClick={() => {
                      let nextValues = [...expands];
                      nextValues[findIndex].expand = !isExpand;
                      setExpands(nextValues);
                    }}>
                    {record.title}
                    <Button
                      className="expand-button"
                      type="text"
                      icon={
                        isExpand ? (
                          <Minus size={20} color="var(--primaryColor)" />
                        ) : (
                          <Plus size={20} color="var(--primaryColor)" />
                        )
                      }
                    />
                  </div>
                  {isExpand && (
                    <div className="expand-content">
                      <RichTextPreview
                        key={record.id}
                        content={record.content}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Button
        shape="circle"
        size="large"
        className="rwd-fixed-button"
        icon={<BookOpen size={22} color="var(--primaryColor)" />}
        onClick={() => setMobileNavVisible(true)}
      />
      <MobileNavDrawer
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  display: flex;
  justify-content: center;

  & > .menu {
    max-width: 210px;
    width: 100%;

    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}

    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  & > .gutter {
    width: 20px;
    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}
  }

  & > .main {
    max-width: 910px;
    width: 100%;
    padding: 10px;

    & > .title {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 6px solid var(--primaryColor);
      margin: 0 auto;
      max-width: 850px;
    }

    & > .content {
      margin: 0 auto;
      max-width: 850px;
      padding-top: 15px;
      padding-bottom: 15px;
      white-space: pre-wrap;

      & > .expand-container {
        border-bottom: 3px solid #c8c8c8;
        padding-top: 10px;
        padding-bottom: 10px;

        & > .expand-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--primaryColor);
          font-size: 0.95rem;
          cursor: pointer;
        }

        & > .expand-button {
        }

        & > .expand-content {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }

  & > .rwd-fixed-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--primaryColor);

    ${(props) => (props.mobile ? `display: block;` : `display: none;`)}
  }
`;

function MobileNavDrawer(props) {
  const {visible, setVisible} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <>
      <MobileNavDrawerBackdrop
        mobile={mobile}
        visible={visible}
        onClick={() => setVisible(false)}
      />
      <MobileNavDrawerWrapper mobile={mobile} visible={visible}>
        <div className="container">
          <Menu mode="inline">
            <Menu.Item className="header">{TITLE}</Menu.Item>
            {ROUTES.map((item, index) => {
              if (item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={`root-${index}`}
                    className="route"
                    title={item.label}>
                    {item.children.map((subroute, index) => (
                      <Menu.Item
                        key={`subroute-${index}`}
                        onClick={() => {
                          AppActions.navigate(subroute.key);
                          setVisible(false);
                        }}>
                        {subroute.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }
              return (
                <Menu.Item
                  key={`subroute-${index}`}
                  className="route"
                  onClick={() => {
                    AppActions.navigate(item.key);
                    setVisible(false);
                  }}>
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </MobileNavDrawerWrapper>
    </>
  );
}

const MobileNavDrawerBackdrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);

  ${(props) => (props.visible ? `display: block;` : `display: none;`)};
`;

const MobileNavDrawerWrapper = styled.div`
  width: 256px;
  height: 100vh;

  position: absolute;
  top: 83px;
  left: -256px;
  transition: 300ms;

  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 30px 10px;

  ${(props) => (props.visible ? `left: 0px;` : ``)}

  & > .container {
    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  max-width: 210px;
  width: 100%;
`;

export default withPageEntry(About);
