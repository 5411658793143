import React from 'react';
import styled from 'styled-components';
import {Button, Pagination, Tag} from 'antd';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as AppActions from '../AppActions';
import * as AppActionsEx from '../AppActions/custom.js';

function Morale(props) {
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [moraleCategories] = useOutlet('moraleCategories');
  const setMoraleQueryModal = useOutletSetter('MoraleQueryModal');

  const [selectedLabel, setSelectedLabel] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  const [query, setQuery] = React.useState({});
  const [total, setTotal] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);

      try {
        let nextQuery;

        if (selectedLabel) {
          nextQuery = {
            labels: selectedLabel,
          };
        } else {
          nextQuery = {
            ...query,
          };

          if (nextQuery.labels) {
            if (nextQuery.labels.length === 0) {
              delete nextQuery.labels;
            } else if (
              nextQuery.labels.$in &&
              nextQuery.labels.$in === undefined
            ) {
              delete nextQuery.labels;
            } else {
              nextQuery.labels = {$in: nextQuery.labels};
            }
          }
        }

        const resp = await AppActionsEx.fetchMorales(
          nextQuery,
          ['-case_number'],
          {
            offset: (pagination.page - 1) * pagination.pageSize,
            limit: pagination.pageSize,
          },
        );

        setRecords(resp.results);
        setTotal(resp.total);
        setPagination(pagination);

        AppActions.setLoading(false);
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [query, pagination, selectedLabel]);

  return (
    <Wrapper mobile={mobile}>
      <div style={{textAlign: 'center'}}>依照類型搜尋</div>
      <div className="dashboard">
        <div className="container">
          {moraleCategories.map((category, index) => {
            const selected = selectedLabel === category.name;
            return (
              <Button
                key={`morale-${index}`}
                className={`btn ${selected ? 'selected' : ''}`}
                onClick={() => {
                  setSelectedLabel(category.name);
                  setPagination({page: 1, pageSize: 10});
                }}>
                {category.display}
              </Button>
            );
          })}
        </div>
      </div>
      <div
        style={{
          marginTop: -10,
          marginBottom: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        <span style={{paddingRight: 10}}>或使用精準搜尋</span>
        <Button
          type="primary"
          onClick={() =>
            setMoraleQueryModal({
              query,
              setQuery: (nextQuery) => {
                setSelectedLabel(null);
                setQuery(nextQuery);
                setPagination({page: 1, pageSize: 10});
              },
            })
          }>
          設定搜尋條件
        </Button>
      </div>

      <div className="content">
        {selectedLabel &&
          (() => {
            const category = moraleCategories.find(
              (category) => category.name === selectedLabel,
            );

            return (
              <div className="kickoff">
                <h3>{selectedLabel}</h3>
                {category && <p>{category.note}</p>}
              </div>
            );
          })()}

        {Array.isArray(records) &&
          records.length === 0 &&
          !selectedLabel &&
          Object.keys(query).length === 0 && (
            <div className="item">
              <h3 className="title">請設定搜尋條件。</h3>
            </div>
          )}

        {Array.isArray(records) &&
          records.length === 0 &&
          (selectedLabel || Object.keys(query).length > 0) && (
            <div className="item">
              <h3 className="title">未有符合結果。</h3>
            </div>
          )}

        {Array.isArray(records) &&
          records.length > 0 &&
          records.map((record, index) => {
            return (
              <div className="item" key={`morale-${index}`}>
                <div className="title-container">
                  <h3 className="title">
                    {record.case_number_prefix}第{record.case_number}號
                  </h3>
                  <div className="tags">
                    {record.labels.map((label) => (
                      <Tag color="gold">{label}</Tag>
                    ))}
                  </div>
                </div>
                <div className="field">
                  <label>決定結果：</label>
                  <span>{record.resolution_result_tba}</span>
                </div>
                <div className="field">
                  <label>所涉法規：</label>
                  <span>{record.resolution_law}</span>
                </div>
                <div className="field">
                  <label>決定要旨：</label>
                  <div>{record.resolution_gist_external}</div>
                </div>
              </div>
            );
          })}
      </div>

      <div className="page-container">
        <Pagination
          defaultCurrent={1}
          current={pagination.page}
          total={total}
          showSizeChanger={true}
          showTotal={(total) => `共 ${total} 筆`}
          onChange={(page, pageSize) => {
            setPagination({page, pageSize});
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .dashboard {
    display: flex;
    flex-wrap: ${(props) => (props.mobile ? 'wrap-reverse' : 'nowrap')};
    margin-top: 10px;
    margin-bottom: 10px;

    & > .container {
      margin-bottom: 10px;
    }

    & .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
    }

    & .selected {
      border: 1px solid var(--primaryColor);
      color: var(--primaryColor);
    }
  }

  & > .content {
    min-height: 100vh;

    & > .kickoff {
      margin-bottom: 25px;

      & > h3 {
        margin-bottom: 20px;
        border-left: 6px solid var(--primaryColor);
        padding-left: 15px;
        font-weight: 600;
        font-size: 1.4rem;
      }

      & > p {
        line-height: 2rem;
        font-size: 1rem;
        white-space: pre-line;
        margin: 0px;
      }
    }

    & > .item {
      margin-top: 25px;
      margin-bottom: 25px;

      & > .title-container {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;

        & > .title {
          margin-bottom: 10px;
          line-height: 2rem;
          font-weight: 600;
          font-size: 1.2rem;
          color: var(--primaryColor);
        }

        & > .tags {
        }
      }

      & > .field {
        margin-bottom: 5px;

        & > label {
          line-height: 2rem;
          font-weight: 600;
          font-size: 1rem;
          color: var(--secondColor);
        }

        & > p,
        span,
        div {
          line-height: 2rem;
          font-size: 1rem;
        }
      }
    }
  }

  & > div.page-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
`;

export default Morale;
