import React from 'react';
import styled from 'styled-components';
import {Input, Table, Button} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../AppActions';

function SearchCollection(props) {
  const {collection, columns, query, shortcutOptions} = props;

  const [total, setTotal] = React.useState(0);
  const [records, setRecords] = React.useState(null);
  const [keyword, setKeyword] = React.useState('');
  const [pagination, setPagination] = React.useState({
    page: 1,
    pageSize: 10,
  });

  const fetchData = React.useCallback(
    async (keyword = '', pagination = {page: 1, pageSize: 10}) => {
      AppActions.setLoading(true);

      let _query = keyword ? query(keyword) : {};
      let _sorting = ['-created'];

      if (collection === 'Magazine') {
        _query.hide = {
          $ne: true,
        };
      } else if (collection === 'Library_Info') {
        _query.is_open = {
          $ne: '0',
        };
      } else if (collection === 'LegalJournal') {
        _query.isOpen = {
          $ne: '0',
        };
        _sorting = ['-Orders'];
      }

      try {
        const resp = await JStorage.fetchDocuments(
          collection,
          _query,
          _sorting,
          {
            offset: (pagination.page - 1) * pagination.pageSize,
            limit: pagination.pageSize,
          },
        );

        setTotal(resp.total);
        setRecords(resp.results);
        setPagination(pagination);

        AppActions.setLoading(false);
      } catch (err) {
        console.warn(err);
        AppActions.setLoading(false);
      }
    },
    [collection, query],
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      {Array.isArray(shortcutOptions) && (
        <div>
          <label>快捷搜尋：</label>
          {shortcutOptions.map((option) => (
            <Button
              type="link"
              style={{margin: 5, padding: 0}}
              onClick={() => {
                setKeyword(option);
                fetchData(option, pagination);
              }}>
              {option}
            </Button>
          ))}
        </div>
      )}
      <div className="search">
        <Input.Search
          placeholder="請輸入查詢關鍵字"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={(value) => fetchData(value, pagination)}
        />
      </div>
      <div className="results">
        <p className="total">共 {total} 筆</p>
        <Table
          rowKey={collection}
          dataSource={records}
          columns={columns}
          pagination={{total, current: pagination.page}}
          onChange={(_pagination) => {
            fetchData(keyword, {
              page: _pagination.current,
              pageSize: _pagination.pageSize,
            });
          }}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > .search {
    margin-top: 20px;
    margin-bottom: 25px;
    max-width: 500px;
    width: 100%;
  }

  & > .results {
    width: 100%;

    & > .total {
      font-weight: 500;
      text-align: center;
    }
  }
`;

export default SearchCollection;
